import { registerIconsBundle } from '@teliads/icons'
import React from 'react'
import AppContext from './src/app-context'
import axios from 'axios'

export const onClientEntry = () => {
  registerIconsBundle()
}

export const onInitialClientRender = () => {
  loadChatAfterLoginCheck()
}

const loadChatAfterLoginCheck = () => {
  checkUserLogin()
}

// Add an event listener for the custom event
if (typeof window !== 'undefined') {
  window.addEventListener('userLoggedOut', () => {
    console.log('User logged out event received. Reloads entire page')
    // Force Load the chat script after logout via Page refresh
    window.location.reload()
  })
}

// Function to handle user logout
const handleUserLogout = () => {
  // Perform logout operations here
  // Reset the login information
  window.dialogueMetadata = () => ({})
  // Dispatch the custom event to reload the chat script
  const event = new CustomEvent('userLoggedOut')
  if (typeof window !== 'undefined') {
    window.dispatchEvent(event)
  }
}

// Function to add the logout button event listener
const addLogoutButtonListener = () => {
  const logoutButton = document.querySelector('.telia-react-userMenu_logoutButton')
  if (logoutButton) {
    console.log('Logout button found')
    logoutButton.addEventListener('click', handleUserLogout)
    return true
  }
  return false
}

// Use MutationObserver to watch for the logout button within the specific container
if (typeof document !== 'undefined') {
  const observer = new window.MutationObserver((mutations) => {
    mutations.forEach(() => {
      if (addLogoutButtonListener()) {
        observer.disconnect()
      }
    })
  })
  observer.observe(document.body, { childList: true, subtree: true })
}

const chatScript = () => {
  window.talkNowEntityId = 'TeliaFi'
  window.talkNowHostName = 'service.chat.telia.fi'
  const talkNowSSLPort = '443'
  window.talkNowUseProxy = false
  window.proxyURL = ''
  window.lekaneServerURL = 'https://' + window.talkNowHostName + ':' + talkNowSSLPort;
  (function (w) {
    function d () {
      const ds = document.createElement('script')
      ds.type = 'text/javascript'
      ds.async = true
      ds.src = w.lekaneServerURL + '/lekane/boot?ei=' + w.talkNowEntityId
      document.head.appendChild(ds)
    }
    if (document.readyState === 'uninitialized' || document.readyState === 'loading') {
      w.addEventListener ? w.addEventListener('load', d, false) : w.attachEvent('onload', d)
    } else {
      d()
    }
  })(window)
}

const checkUserLogin = async () => {
  try {
    // Ensure the code runs only in the browser
    if (typeof window === 'undefined') {
      return
    }

    console.log('checkUserLogin function called')
    // Make the API call if login status is not stored
    const response = await axios.get('https://www.telia.fi/.rest/login/chat/userinfo')
    const data = response.data
    console.log('User loggedin ?:', data.loggedInStatus)
    if (data.loggedInStatus) {
      const encryptedBPN = data.user.encryptedUserDataForLekane
      if (encryptedBPN) {
        window.dialogueMetadata = () => ({
          MdAuthJWT: encryptedBPN
        })
        console.log('BPN set and now chat is called after login check')
        chatScript()
      }
      console.log('Chat loading completed')
    } else {
      console.log('User is not logged in')
      console.log('Login Check completed and chat is now loaded ')
      chatScript()
    }
  } catch (error) {
    console.error('Error checking user login:', error)
    chatScript()
  }
}

// Update One trust on every route change
const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')
  if (otConsentSdk) {
    otConsentSdk.remove()
  }

  if (window.OneTrust != null) {
    window.OneTrust.Init()

    setTimeout(function () {
      window.OneTrust.LoadBanner()

      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings'
      )

      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }
    }, 1000)
  }
}

export const onRouteUpdate = () => {
  reloadOTBanner()
}

export const wrapRootElement = ({ element }) => {
  <AppContext.Provider>
    {element}
  </AppContext.Provider>
}
